import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
@Pipe({
  name: 'primaryMuscles',
  standalone: true,
})
export class PrimaryMusclesPipe implements PipeTransform {
  constructor(private translocoService: TranslocoService) {}
  transform(primaryMuscles: string[], activeLang?: string) {
    if (!primaryMuscles) return null;

    return this.translocoService.translate(primaryMuscles, {}, activeLang);
  }
}
