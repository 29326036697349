import { ChangeDetectorRef, Component, OnDestroy, inject } from '@angular/core';
// import { SubSink } from 'subsink/dist/subsink';
import { RoleType } from '@goatsports/core/data-access';
import { ErrorService } from '../services/error.service';
import { LoadingService } from '../services/loading.service';
import { LoadingStatus } from '../models/loading-status';

@Component({
  template: '',
})
export class BaseComponent implements OnDestroy {
  readonly LoadingStatus = LoadingStatus;
  readonly RoleType = RoleType;
  cd = inject(ChangeDetectorRef);
  errorService = inject(ErrorService);
  loadingService = inject(LoadingService);
  // protected subs = new SubSink();
  isWaiting = false;

  ngOnDestroy() {
    // this.subs.unsubscribe();
    this.errorService.clearServerErrors();
  }
}
