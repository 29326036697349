import { Pipe, PipeTransform } from '@angular/core';
import { UserExerciseAttributeModel } from '@goatsports/core/data-access';
import { translate } from '@jsverse/transloco';
import { WorkoutTimeFormatPipe } from './workout-time-format.pipe';

@Pipe({
  name: 'filterWorkoutAttributes',
  standalone: true,
})
export class FilterWorkoutAttributesPipe implements PipeTransform {
  readonly attributeNamesForFormatTime = [
    'rest',
    'time',
    'timeperside',
    'represt',
  ];

  transform(
    attributes: UserExerciseAttributeModel[] | undefined,
    language: string | undefined
  ) {
    if (!attributes || !language) return;
    const filteredAttribute = attributes.filter(
      (attribute) =>
        attribute?.exerciseAttributeTypeUid !== 'set' &&
        attribute?.exerciseAttributeTypeUid !== 'rest'
    );
    const htmlString = filteredAttribute
      .map((attribute) => {
        // Check if the attribute name is in the list of names for which we don't show unit
        const shouldFormatTimeWithoutUnit =
          this.attributeNamesForFormatTime.includes(
            attribute.exerciseAttributeTypeUid ?? ''
          );
        // Format the value with or without unit based on the attribute name
        const formattedValue = shouldFormatTimeWithoutUnit
          ? new WorkoutTimeFormatPipe().transform(attribute.value)
          : `${attribute.value}${attribute.unit ?? ''}`;

        return `<span class="child-row__bold">${translate(
          attribute.name ?? '',
          {},
          language
        )}</span>: <span class="child-row">${formattedValue}</span>`;
      })
      .join(', ');
    return htmlString;
  }
}
