/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateUserHabitEventCommand } from '../model/createUserHabitEventCommand';
import { CreateUserHabitEventCommandResult } from '../model/createUserHabitEventCommandResult';
import { GetAssignedHabitsQueryResult } from '../model/getAssignedHabitsQueryResult';
import { GetUserHabitEventQueryResult } from '../model/getUserHabitEventQueryResult';
import { GetUserHabitEventsQueryResult } from '../model/getUserHabitEventsQueryResult';
import { GetUserHabitStatisticsQueryResult } from '../model/getUserHabitStatisticsQueryResult';
import { InlineResponse400 } from '../model/inlineResponse400';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UserHabitEventsService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param selectedDateLocal 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userHabitEventsGet(selectedDateLocal?: string, observe?: 'body', reportProgress?: boolean): Observable<GetUserHabitEventsQueryResult>;
    public userHabitEventsGet(selectedDateLocal?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetUserHabitEventsQueryResult>>;
    public userHabitEventsGet(selectedDateLocal?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetUserHabitEventsQueryResult>>;
    public userHabitEventsGet(selectedDateLocal?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (selectedDateLocal !== undefined && selectedDateLocal !== null) {
            queryParameters = queryParameters.set('SelectedDateLocal', <any>selectedDateLocal);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetUserHabitEventsQueryResult>('get',`${this.basePath}/UserHabitEvents`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param clientUid 
     * @param startDateTimeUTC 
     * @param endDateTimeUTC 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userHabitEventsGetAssignedHabitEventsGet(clientUid?: string, startDateTimeUTC?: string, endDateTimeUTC?: string, observe?: 'body', reportProgress?: boolean): Observable<GetAssignedHabitsQueryResult>;
    public userHabitEventsGetAssignedHabitEventsGet(clientUid?: string, startDateTimeUTC?: string, endDateTimeUTC?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetAssignedHabitsQueryResult>>;
    public userHabitEventsGetAssignedHabitEventsGet(clientUid?: string, startDateTimeUTC?: string, endDateTimeUTC?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetAssignedHabitsQueryResult>>;
    public userHabitEventsGetAssignedHabitEventsGet(clientUid?: string, startDateTimeUTC?: string, endDateTimeUTC?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (clientUid !== undefined && clientUid !== null) {
            queryParameters = queryParameters.set('ClientUid', <any>clientUid);
        }
        if (startDateTimeUTC !== undefined && startDateTimeUTC !== null) {
            queryParameters = queryParameters.set('StartDateTimeUTC', <any>startDateTimeUTC);
        }
        if (endDateTimeUTC !== undefined && endDateTimeUTC !== null) {
            queryParameters = queryParameters.set('EndDateTimeUTC', <any>endDateTimeUTC);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetAssignedHabitsQueryResult>('get',`${this.basePath}/UserHabitEvents/GetAssignedHabitEvents`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param clientUid 
     * @param startDateLocal 
     * @param endDateLocal 
     * @param pageNumber 
     * @param pageSize 
     * @param searchText 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userHabitEventsGetUserHabitStatitisticsGet(clientUid?: string, startDateLocal?: string, endDateLocal?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'body', reportProgress?: boolean): Observable<GetUserHabitStatisticsQueryResult>;
    public userHabitEventsGetUserHabitStatitisticsGet(clientUid?: string, startDateLocal?: string, endDateLocal?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetUserHabitStatisticsQueryResult>>;
    public userHabitEventsGetUserHabitStatitisticsGet(clientUid?: string, startDateLocal?: string, endDateLocal?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetUserHabitStatisticsQueryResult>>;
    public userHabitEventsGetUserHabitStatitisticsGet(clientUid?: string, startDateLocal?: string, endDateLocal?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (clientUid !== undefined && clientUid !== null) {
            queryParameters = queryParameters.set('ClientUid', <any>clientUid);
        }
        if (startDateLocal !== undefined && startDateLocal !== null) {
            queryParameters = queryParameters.set('StartDateLocal', <any>startDateLocal);
        }
        if (endDateLocal !== undefined && endDateLocal !== null) {
            queryParameters = queryParameters.set('EndDateLocal', <any>endDateLocal);
        }
        if (pageNumber !== undefined && pageNumber !== null) {
            queryParameters = queryParameters.set('PageNumber', <any>pageNumber);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (searchText !== undefined && searchText !== null) {
            queryParameters = queryParameters.set('SearchText', <any>searchText);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetUserHabitStatisticsQueryResult>('get',`${this.basePath}/UserHabitEvents/GetUserHabitStatitistics`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [PORTAL] and [APP] Create habit event for selected client or self assign
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userHabitEventsPost(body?: CreateUserHabitEventCommand, observe?: 'body', reportProgress?: boolean): Observable<CreateUserHabitEventCommandResult>;
    public userHabitEventsPost(body?: CreateUserHabitEventCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreateUserHabitEventCommandResult>>;
    public userHabitEventsPost(body?: CreateUserHabitEventCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreateUserHabitEventCommandResult>>;
    public userHabitEventsPost(body?: CreateUserHabitEventCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CreateUserHabitEventCommandResult>('post',`${this.basePath}/UserHabitEvents`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uid 
     * @param selectedDateLocal 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userHabitEventsUidDelete(uid: string, selectedDateLocal?: string, observe?: 'body', reportProgress?: boolean): Observable<GetUserHabitEventsQueryResult>;
    public userHabitEventsUidDelete(uid: string, selectedDateLocal?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetUserHabitEventsQueryResult>>;
    public userHabitEventsUidDelete(uid: string, selectedDateLocal?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetUserHabitEventsQueryResult>>;
    public userHabitEventsUidDelete(uid: string, selectedDateLocal?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling userHabitEventsUidDelete.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (selectedDateLocal !== undefined && selectedDateLocal !== null) {
            queryParameters = queryParameters.set('SelectedDateLocal', <any>selectedDateLocal);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetUserHabitEventsQueryResult>('delete',`${this.basePath}/UserHabitEvents/${encodeURIComponent(String(uid))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userHabitEventsUidGet(uid: string, observe?: 'body', reportProgress?: boolean): Observable<GetUserHabitEventQueryResult>;
    public userHabitEventsUidGet(uid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetUserHabitEventQueryResult>>;
    public userHabitEventsUidGet(uid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetUserHabitEventQueryResult>>;
    public userHabitEventsUidGet(uid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling userHabitEventsUidGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetUserHabitEventQueryResult>('get',`${this.basePath}/UserHabitEvents/${encodeURIComponent(String(uid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
