/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ChangeUserQuizStatusCommand } from '../model/changeUserQuizStatusCommand';
import { CheckAnswerQueryResult } from '../model/checkAnswerQueryResult';
import { CreateUserQuizEventCommand } from '../model/createUserQuizEventCommand';
import { CreateUserQuizEventCommandResult } from '../model/createUserQuizEventCommandResult';
import { GetAssignedQuizzesQueryResult } from '../model/getAssignedQuizzesQueryResult';
import { GetUserQuizEventQueryResult } from '../model/getUserQuizEventQueryResult';
import { GetUserQuizQueryResult } from '../model/getUserQuizQueryResult';
import { GetUserQuizStatisticsQueryResult } from '../model/getUserQuizStatisticsQueryResult';
import { GetUserQuizzesQueryResult } from '../model/getUserQuizzesQueryResult';
import { InlineResponse400 } from '../model/inlineResponse400';
import { SubmitQuestionAnswerCommand } from '../model/submitQuestionAnswerCommand';
import { SubmitQuestionAnswerCommandResult } from '../model/submitQuestionAnswerCommandResult';
import { UserQuizStatusType } from '../model/userQuizStatusType';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UserQuizzesService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * [MOBILE] Checks if answer is correct
     * 
     * @param uid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userQuizzesCheckAnswerGet(uid?: string, observe?: 'body', reportProgress?: boolean): Observable<CheckAnswerQueryResult>;
    public userQuizzesCheckAnswerGet(uid?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CheckAnswerQueryResult>>;
    public userQuizzesCheckAnswerGet(uid?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CheckAnswerQueryResult>>;
    public userQuizzesCheckAnswerGet(uid?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (uid !== undefined && uid !== null) {
            queryParameters = queryParameters.set('Uid', <any>uid);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CheckAnswerQueryResult>('get',`${this.basePath}/UserQuizzes/CheckAnswer`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [MOBILE] Client gets quizzes for selected date
     * 
     * @param selectedDateTimeLocal 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userQuizzesGet(selectedDateTimeLocal?: string, observe?: 'body', reportProgress?: boolean): Observable<GetUserQuizzesQueryResult>;
    public userQuizzesGet(selectedDateTimeLocal?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetUserQuizzesQueryResult>>;
    public userQuizzesGet(selectedDateTimeLocal?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetUserQuizzesQueryResult>>;
    public userQuizzesGet(selectedDateTimeLocal?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (selectedDateTimeLocal !== undefined && selectedDateTimeLocal !== null) {
            queryParameters = queryParameters.set('SelectedDateTimeLocal', <any>selectedDateTimeLocal);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetUserQuizzesQueryResult>('get',`${this.basePath}/UserQuizzes`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [PORTAL] Gets clients assigned quiz events
     * 
     * @param clientUid 
     * @param startDateTimeUTC 
     * @param endDateTimeUTC 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userQuizzesGetAssignedQuizzesGet(clientUid?: string, startDateTimeUTC?: string, endDateTimeUTC?: string, observe?: 'body', reportProgress?: boolean): Observable<GetAssignedQuizzesQueryResult>;
    public userQuizzesGetAssignedQuizzesGet(clientUid?: string, startDateTimeUTC?: string, endDateTimeUTC?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetAssignedQuizzesQueryResult>>;
    public userQuizzesGetAssignedQuizzesGet(clientUid?: string, startDateTimeUTC?: string, endDateTimeUTC?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetAssignedQuizzesQueryResult>>;
    public userQuizzesGetAssignedQuizzesGet(clientUid?: string, startDateTimeUTC?: string, endDateTimeUTC?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (clientUid !== undefined && clientUid !== null) {
            queryParameters = queryParameters.set('ClientUid', <any>clientUid);
        }
        if (startDateTimeUTC !== undefined && startDateTimeUTC !== null) {
            queryParameters = queryParameters.set('StartDateTimeUTC', <any>startDateTimeUTC);
        }
        if (endDateTimeUTC !== undefined && endDateTimeUTC !== null) {
            queryParameters = queryParameters.set('EndDateTimeUTC', <any>endDateTimeUTC);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetAssignedQuizzesQueryResult>('get',`${this.basePath}/UserQuizzes/GetAssignedQuizzes`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [PORTAL] Assings quiz to a client
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userQuizzesPost(body?: CreateUserQuizEventCommand, observe?: 'body', reportProgress?: boolean): Observable<CreateUserQuizEventCommandResult>;
    public userQuizzesPost(body?: CreateUserQuizEventCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreateUserQuizEventCommandResult>>;
    public userQuizzesPost(body?: CreateUserQuizEventCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreateUserQuizEventCommandResult>>;
    public userQuizzesPost(body?: CreateUserQuizEventCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CreateUserQuizEventCommandResult>('post',`${this.basePath}/UserQuizzes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [MOBILE] Client gets single quiz with answers if exists
     * 
     * @param quizEventUid 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userQuizzesQuizEventUidGet(quizEventUid: string, status?: UserQuizStatusType, observe?: 'body', reportProgress?: boolean): Observable<GetUserQuizQueryResult>;
    public userQuizzesQuizEventUidGet(quizEventUid: string, status?: UserQuizStatusType, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetUserQuizQueryResult>>;
    public userQuizzesQuizEventUidGet(quizEventUid: string, status?: UserQuizStatusType, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetUserQuizQueryResult>>;
    public userQuizzesQuizEventUidGet(quizEventUid: string, status?: UserQuizStatusType, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (quizEventUid === null || quizEventUid === undefined) {
            throw new Error('Required parameter quizEventUid was null or undefined when calling userQuizzesQuizEventUidGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('Status', <any>status);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetUserQuizQueryResult>('get',`${this.basePath}/UserQuizzes/${encodeURIComponent(String(quizEventUid))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [MOBILE] Submits question answer
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userQuizzesSubmitPost(body?: SubmitQuestionAnswerCommand, observe?: 'body', reportProgress?: boolean): Observable<SubmitQuestionAnswerCommandResult>;
    public userQuizzesSubmitPost(body?: SubmitQuestionAnswerCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SubmitQuestionAnswerCommandResult>>;
    public userQuizzesSubmitPost(body?: SubmitQuestionAnswerCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SubmitQuestionAnswerCommandResult>>;
    public userQuizzesSubmitPost(body?: SubmitQuestionAnswerCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SubmitQuestionAnswerCommandResult>('post',`${this.basePath}/UserQuizzes/Submit`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [MOBILE] Changes quiz status when finished
     * 
     * @param uid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userQuizzesUidPatch(uid: string, body?: ChangeUserQuizStatusCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public userQuizzesUidPatch(uid: string, body?: ChangeUserQuizStatusCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public userQuizzesUidPatch(uid: string, body?: ChangeUserQuizStatusCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public userQuizzesUidPatch(uid: string, body?: ChangeUserQuizStatusCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling userQuizzesUidPatch.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('patch',`${this.basePath}/UserQuizzes/${encodeURIComponent(String(uid))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [PORTAL] Gets clients quiz event preview with statistics
     * 
     * @param uid 
     * @param clientUid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userQuizzesUserQuizEventUidGet(uid: string, clientUid?: string, observe?: 'body', reportProgress?: boolean): Observable<GetUserQuizEventQueryResult>;
    public userQuizzesUserQuizEventUidGet(uid: string, clientUid?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetUserQuizEventQueryResult>>;
    public userQuizzesUserQuizEventUidGet(uid: string, clientUid?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetUserQuizEventQueryResult>>;
    public userQuizzesUserQuizEventUidGet(uid: string, clientUid?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling userQuizzesUserQuizEventUidGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (clientUid !== undefined && clientUid !== null) {
            queryParameters = queryParameters.set('ClientUid', <any>clientUid);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetUserQuizEventQueryResult>('get',`${this.basePath}/UserQuizzes/UserQuizEvent/${encodeURIComponent(String(uid))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [PORTAL] Gets clients quiz events statistics 
     * 
     * @param clientUid 
     * @param quizEventUid 
     * @param startDateTimeUTC 
     * @param endDateTimeUTC 
     * @param pageNumber 
     * @param pageSize 
     * @param searchText 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userQuizzesUserQuizStatisticsGet(clientUid?: string, quizEventUid?: string, startDateTimeUTC?: string, endDateTimeUTC?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'body', reportProgress?: boolean): Observable<GetUserQuizStatisticsQueryResult>;
    public userQuizzesUserQuizStatisticsGet(clientUid?: string, quizEventUid?: string, startDateTimeUTC?: string, endDateTimeUTC?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetUserQuizStatisticsQueryResult>>;
    public userQuizzesUserQuizStatisticsGet(clientUid?: string, quizEventUid?: string, startDateTimeUTC?: string, endDateTimeUTC?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetUserQuizStatisticsQueryResult>>;
    public userQuizzesUserQuizStatisticsGet(clientUid?: string, quizEventUid?: string, startDateTimeUTC?: string, endDateTimeUTC?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (clientUid !== undefined && clientUid !== null) {
            queryParameters = queryParameters.set('ClientUid', <any>clientUid);
        }
        if (quizEventUid !== undefined && quizEventUid !== null) {
            queryParameters = queryParameters.set('QuizEventUid', <any>quizEventUid);
        }
        if (startDateTimeUTC !== undefined && startDateTimeUTC !== null) {
            queryParameters = queryParameters.set('StartDateTimeUTC', <any>startDateTimeUTC);
        }
        if (endDateTimeUTC !== undefined && endDateTimeUTC !== null) {
            queryParameters = queryParameters.set('EndDateTimeUTC', <any>endDateTimeUTC);
        }
        if (pageNumber !== undefined && pageNumber !== null) {
            queryParameters = queryParameters.set('PageNumber', <any>pageNumber);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (searchText !== undefined && searchText !== null) {
            queryParameters = queryParameters.set('SearchText', <any>searchText);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetUserQuizStatisticsQueryResult>('get',`${this.basePath}/UserQuizzes/UserQuizStatistics`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
