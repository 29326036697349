/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateQuizEventCommand } from '../model/createQuizEventCommand';
import { CreateQuizEventCommandResult } from '../model/createQuizEventCommandResult';
import { DeleteQuizEventCommandResult } from '../model/deleteQuizEventCommandResult';
import { GetQuizEventQueryResult } from '../model/getQuizEventQueryResult';
import { GetQuizEventsQueryResult } from '../model/getQuizEventsQueryResult';
import { InlineResponse400 } from '../model/inlineResponse400';
import { UpdateQuizEventCommand } from '../model/updateQuizEventCommand';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class QuizEventsService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param startDateTimeUTC 
     * @param endDateTimeUTC 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public quizEventsGet(startDateTimeUTC?: string, endDateTimeUTC?: string, observe?: 'body', reportProgress?: boolean): Observable<GetQuizEventsQueryResult>;
    public quizEventsGet(startDateTimeUTC?: string, endDateTimeUTC?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetQuizEventsQueryResult>>;
    public quizEventsGet(startDateTimeUTC?: string, endDateTimeUTC?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetQuizEventsQueryResult>>;
    public quizEventsGet(startDateTimeUTC?: string, endDateTimeUTC?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startDateTimeUTC !== undefined && startDateTimeUTC !== null) {
            queryParameters = queryParameters.set('StartDateTimeUTC', <any>startDateTimeUTC);
        }
        if (endDateTimeUTC !== undefined && endDateTimeUTC !== null) {
            queryParameters = queryParameters.set('EndDateTimeUTC', <any>endDateTimeUTC);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetQuizEventsQueryResult>('get',`${this.basePath}/QuizEvents`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public quizEventsPost(body?: CreateQuizEventCommand, observe?: 'body', reportProgress?: boolean): Observable<CreateQuizEventCommandResult>;
    public quizEventsPost(body?: CreateQuizEventCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreateQuizEventCommandResult>>;
    public quizEventsPost(body?: CreateQuizEventCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreateQuizEventCommandResult>>;
    public quizEventsPost(body?: CreateQuizEventCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CreateQuizEventCommandResult>('post',`${this.basePath}/QuizEvents`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public quizEventsUidDelete(uid: string, observe?: 'body', reportProgress?: boolean): Observable<DeleteQuizEventCommandResult>;
    public quizEventsUidDelete(uid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeleteQuizEventCommandResult>>;
    public quizEventsUidDelete(uid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeleteQuizEventCommandResult>>;
    public quizEventsUidDelete(uid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling quizEventsUidDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DeleteQuizEventCommandResult>('delete',`${this.basePath}/QuizEvents/${encodeURIComponent(String(uid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public quizEventsUidGet(uid: string, observe?: 'body', reportProgress?: boolean): Observable<GetQuizEventQueryResult>;
    public quizEventsUidGet(uid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetQuizEventQueryResult>>;
    public quizEventsUidGet(uid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetQuizEventQueryResult>>;
    public quizEventsUidGet(uid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling quizEventsUidGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetQuizEventQueryResult>('get',`${this.basePath}/QuizEvents/${encodeURIComponent(String(uid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public quizEventsUidPut(uid: string, body?: UpdateQuizEventCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public quizEventsUidPut(uid: string, body?: UpdateQuizEventCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public quizEventsUidPut(uid: string, body?: UpdateQuizEventCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public quizEventsUidPut(uid: string, body?: UpdateQuizEventCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling quizEventsUidPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/QuizEvents/${encodeURIComponent(String(uid))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
