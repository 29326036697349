/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { GetExerciseAttributeTypesQuery } from '../model/getExerciseAttributeTypesQuery';
import { GetExerciseAttributeTypesQueryResult } from '../model/getExerciseAttributeTypesQueryResult';
import { GetExerciseAttributesQuery } from '../model/getExerciseAttributesQuery';
import { GetExerciseAttributesQueryResult } from '../model/getExerciseAttributesQueryResult';
import { InlineResponse400 } from '../model/inlineResponse400';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ExerciseAttributesService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Gets all exercise attributes
     * Gets all attributes from all categories
     * @param query 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exerciseAttributesGet(query?: GetExerciseAttributesQuery, observe?: 'body', reportProgress?: boolean): Observable<GetExerciseAttributesQueryResult>;
    public exerciseAttributesGet(query?: GetExerciseAttributesQuery, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetExerciseAttributesQueryResult>>;
    public exerciseAttributesGet(query?: GetExerciseAttributesQuery, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetExerciseAttributesQueryResult>>;
    public exerciseAttributesGet(query?: GetExerciseAttributesQuery, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetExerciseAttributesQueryResult>('get',`${this.basePath}/ExerciseAttributes`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param query 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exerciseAttributesTypesGet(query?: GetExerciseAttributeTypesQuery, observe?: 'body', reportProgress?: boolean): Observable<GetExerciseAttributeTypesQueryResult>;
    public exerciseAttributesTypesGet(query?: GetExerciseAttributeTypesQuery, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetExerciseAttributeTypesQueryResult>>;
    public exerciseAttributesTypesGet(query?: GetExerciseAttributeTypesQuery, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetExerciseAttributeTypesQueryResult>>;
    public exerciseAttributesTypesGet(query?: GetExerciseAttributeTypesQuery, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetExerciseAttributeTypesQueryResult>('get',`${this.basePath}/ExerciseAttributes/Types`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
