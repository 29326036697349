/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateMealPlanCommand } from '../model/createMealPlanCommand';
import { CreateMealPlanCommandResult } from '../model/createMealPlanCommandResult';
import { CreatePlanCommand } from '../model/createPlanCommand';
import { CreatePlanCommandResult } from '../model/createPlanCommandResult';
import { DeleteMealPlanCommand } from '../model/deleteMealPlanCommand';
import { DeleteMealPlanCommandResult } from '../model/deleteMealPlanCommandResult';
import { DeletePlanCommand } from '../model/deletePlanCommand';
import { DeletePlanCommandResult } from '../model/deletePlanCommandResult';
import { DeleteSinglePlanDayCommand } from '../model/deleteSinglePlanDayCommand';
import { GetPlansQueryResult } from '../model/getPlansQueryResult';
import { GetSinglePlanQueryResult } from '../model/getSinglePlanQueryResult';
import { InlineResponse400 } from '../model/inlineResponse400';
import { MealPlansSortSpecification } from '../model/mealPlansSortSpecification';
import { PlanType } from '../model/planType';
import { UpdateMealPlanCommand } from '../model/updateMealPlanCommand';
import { UpdatePlanCommand } from '../model/updatePlanCommand';
import { UpdatePlanScheduleCommand } from '../model/updatePlanScheduleCommand';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PlansService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param uid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mealPlanUidDelete(uid: string, body?: DeleteMealPlanCommand, observe?: 'body', reportProgress?: boolean): Observable<DeleteMealPlanCommandResult>;
    public mealPlanUidDelete(uid: string, body?: DeleteMealPlanCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeleteMealPlanCommandResult>>;
    public mealPlanUidDelete(uid: string, body?: DeleteMealPlanCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeleteMealPlanCommandResult>>;
    public mealPlanUidDelete(uid: string, body?: DeleteMealPlanCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling mealPlanUidDelete.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DeleteMealPlanCommandResult>('delete',`${this.basePath}/MealPlan/${encodeURIComponent(String(uid))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public plansDeletePlanDayDelete(body?: DeleteSinglePlanDayCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public plansDeletePlanDayDelete(body?: DeleteSinglePlanDayCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public plansDeletePlanDayDelete(body?: DeleteSinglePlanDayCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public plansDeletePlanDayDelete(body?: DeleteSinglePlanDayCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('delete',`${this.basePath}/Plans/DeletePlanDay`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sort 
     * @param type 
     * @param filterMinLength 
     * @param filterMaxLength 
     * @param filterCreatedByUserUid 
     * @param filterTags 
     * @param pageNumber 
     * @param pageSize 
     * @param searchText 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public plansGet(sort?: Array<MealPlansSortSpecification>, type?: PlanType, filterMinLength?: number, filterMaxLength?: number, filterCreatedByUserUid?: string, filterTags?: Array<string>, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'body', reportProgress?: boolean): Observable<GetPlansQueryResult>;
    public plansGet(sort?: Array<MealPlansSortSpecification>, type?: PlanType, filterMinLength?: number, filterMaxLength?: number, filterCreatedByUserUid?: string, filterTags?: Array<string>, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetPlansQueryResult>>;
    public plansGet(sort?: Array<MealPlansSortSpecification>, type?: PlanType, filterMinLength?: number, filterMaxLength?: number, filterCreatedByUserUid?: string, filterTags?: Array<string>, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetPlansQueryResult>>;
    public plansGet(sort?: Array<MealPlansSortSpecification>, type?: PlanType, filterMinLength?: number, filterMaxLength?: number, filterCreatedByUserUid?: string, filterTags?: Array<string>, pageNumber?: number, pageSize?: number, searchText?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {










        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('Sort', <any>element);
            })
        }
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('Type', <any>type);
        }
        if (filterMinLength !== undefined && filterMinLength !== null) {
            queryParameters = queryParameters.set('Filter.MinLength', <any>filterMinLength);
        }
        if (filterMaxLength !== undefined && filterMaxLength !== null) {
            queryParameters = queryParameters.set('Filter.MaxLength', <any>filterMaxLength);
        }
        if (filterCreatedByUserUid !== undefined && filterCreatedByUserUid !== null) {
            queryParameters = queryParameters.set('Filter.CreatedByUserUid', <any>filterCreatedByUserUid);
        }
        if (filterTags) {
            filterTags.forEach((element) => {
                queryParameters = queryParameters.append('Filter.Tags', <any>element);
            })
        }
        if (pageNumber !== undefined && pageNumber !== null) {
            queryParameters = queryParameters.set('PageNumber', <any>pageNumber);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (searchText !== undefined && searchText !== null) {
            queryParameters = queryParameters.set('SearchText', <any>searchText);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetPlansQueryResult>('get',`${this.basePath}/Plans`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates meal for logged selected plan
     * UserUid Creates plan meal with MealUid with number of repetitions and stopAfter.
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public plansMealPlanPost(body?: CreateMealPlanCommand, observe?: 'body', reportProgress?: boolean): Observable<CreateMealPlanCommandResult>;
    public plansMealPlanPost(body?: CreateMealPlanCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreateMealPlanCommandResult>>;
    public plansMealPlanPost(body?: CreateMealPlanCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreateMealPlanCommandResult>>;
    public plansMealPlanPost(body?: CreateMealPlanCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CreateMealPlanCommandResult>('post',`${this.basePath}/Plans/MealPlan`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates plan with Name and Length for logged coach
     * UserUid Creates PlanUid with Name and Length
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public plansPost(body?: CreatePlanCommand, observe?: 'body', reportProgress?: boolean): Observable<CreatePlanCommandResult>;
    public plansPost(body?: CreatePlanCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreatePlanCommandResult>>;
    public plansPost(body?: CreatePlanCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreatePlanCommandResult>>;
    public plansPost(body?: CreatePlanCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CreatePlanCommandResult>('post',`${this.basePath}/Plans`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public plansUidDelete(uid: string, body?: DeletePlanCommand, observe?: 'body', reportProgress?: boolean): Observable<DeletePlanCommandResult>;
    public plansUidDelete(uid: string, body?: DeletePlanCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeletePlanCommandResult>>;
    public plansUidDelete(uid: string, body?: DeletePlanCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeletePlanCommandResult>>;
    public plansUidDelete(uid: string, body?: DeletePlanCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling plansUidDelete.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DeletePlanCommandResult>('delete',`${this.basePath}/Plans/${encodeURIComponent(String(uid))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uid 
     * @param isPreview 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public plansUidGet(uid: string, isPreview?: boolean, observe?: 'body', reportProgress?: boolean): Observable<GetSinglePlanQueryResult>;
    public plansUidGet(uid: string, isPreview?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetSinglePlanQueryResult>>;
    public plansUidGet(uid: string, isPreview?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetSinglePlanQueryResult>>;
    public plansUidGet(uid: string, isPreview?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling plansUidGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (isPreview !== undefined && isPreview !== null) {
            queryParameters = queryParameters.set('isPreview', <any>isPreview);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetSinglePlanQueryResult>('get',`${this.basePath}/Plans/${encodeURIComponent(String(uid))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public plansUidPut(uid: string, body?: UpdatePlanCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public plansUidPut(uid: string, body?: UpdatePlanCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public plansUidPut(uid: string, body?: UpdatePlanCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public plansUidPut(uid: string, body?: UpdatePlanCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling plansUidPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/Plans/${encodeURIComponent(String(uid))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public plansUidServingsPatch(uid: string, body?: UpdateMealPlanCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public plansUidServingsPatch(uid: string, body?: UpdateMealPlanCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public plansUidServingsPatch(uid: string, body?: UpdateMealPlanCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public plansUidServingsPatch(uid: string, body?: UpdateMealPlanCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling plansUidServingsPatch.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('patch',`${this.basePath}/Plans/${encodeURIComponent(String(uid))}/Servings`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public plansUpdatePlanSchedulePut(body?: UpdatePlanScheduleCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public plansUpdatePlanSchedulePut(body?: UpdatePlanScheduleCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public plansUpdatePlanSchedulePut(body?: UpdatePlanScheduleCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public plansUpdatePlanSchedulePut(body?: UpdatePlanScheduleCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/Plans/UpdatePlanSchedule`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
