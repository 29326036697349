import {
  NgModule,
  ModuleWithProviders,
  SkipSelf,
  Optional,
} from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { CoachesService } from './api/coaches.service';
import { CommentsService } from './api/comments.service';
import { DashboardService } from './api/dashboard.service';
import { DiningChoicesService } from './api/diningChoices.service';
import { ExerciseService } from './api/exercise.service';
import { ExerciseAttributesService } from './api/exerciseAttributes.service';
import { GoalMetricsService } from './api/goalMetrics.service';
import { GoalsService } from './api/goals.service';
import { GroupsService } from './api/groups.service';
import { IngredientTranslationsService } from './api/ingredientTranslations.service';
import { IngredientsService } from './api/ingredients.service';
import { InvitationService } from './api/invitation.service';
import { LanguagesService } from './api/languages.service';
import { MealTranslationsService } from './api/mealTranslations.service';
import { MealsService } from './api/meals.service';
import { MeasurementsService } from './api/measurements.service';
import { MessagesService } from './api/messages.service';
import { MetricsService } from './api/metrics.service';
import { NoteService } from './api/note.service';
import { NotificationService } from './api/notification.service';
import { OrganizationsService } from './api/organizations.service';
import { PlansService } from './api/plans.service';
import { ProgramService } from './api/program.service';
import { QuestionsService } from './api/questions.service';
import { ReflectionService } from './api/reflection.service';
import { StatisticService } from './api/statistic.service';
import { StripeService } from './api/stripe.service';
import { SubscriptionsService } from './api/subscriptions.service';
import { TeamsService } from './api/teams.service';
import { TranslationsService } from './api/translations.service';
import { UnitsService } from './api/units.service';
import { UserMealsService } from './api/userMeals.service';
import { UserWorkoutsService } from './api/userWorkouts.service';
import { UsersService } from './api/users.service';
import { VersionsService } from './api/versions.service';
import { WorkoutsService } from './api/workouts.service';
import { HabitsService } from './api/habits.service';
import { QuizzesService } from './api/quizzes.service';
import { QuizQuestionsService } from './api/quizQuestions.service';
import { UserQuizzesService } from './api/userQuizzes.service';
import { OrganizationAdminsService } from './api/organizationAdmins.service';
import { UserRemindersService } from './api/userReminders.service';
import { WorkoutCategoriesService } from './api/workoutCategories.service';
import { TagsService } from './api/tags.service';
import { ExerciseCategoriesService } from './api/exerciseCategories.service';
import { ExercisePrimaryMusclesService } from './api/exercisePrimaryMuscles.service';
import { ExerciseEquipmentService } from './api/exerciseEquipment.service';
import { ExercisePatternsService } from './api/exercisePatterns.service';
import { ExercisePlanesService } from './api/exercisePlanes.service';
import { HabitCategoriesService } from './api/habitCategories.service';
import { MetricCategoriesService } from './api/metricCategories.service';
import { QuizEventsService } from './api/quizEvents.service';
import { OwnershipLevelsService } from './api/ownershipLevels.service';
import { ReflectionEventsService } from './api/reflectionEvents.service';
import { UserReflectionEventsService } from './api/userReflectionEvents.service';
import { HabitEventsService } from './api/habitEvents.service';
import { UserHabitEventsService } from './api/userHabitEvents.service';
import { UserHabitEventLogsService } from './api/userHabitEventLogs.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    CoachesService,
    CommentsService,
    DashboardService,
    DiningChoicesService,
    ExerciseService,
    ExerciseAttributesService,
    GoalMetricsService,
    GoalsService,
    GroupsService,
    IngredientTranslationsService,
    IngredientsService,
    InvitationService,
    LanguagesService,
    MealTranslationsService,
    MealsService,
    MeasurementsService,
    MessagesService,
    MetricsService,
    NoteService,
    NotificationService,
    OrganizationsService,
    PlansService,
    ProgramService,
    QuestionsService,
    ReflectionService,
    StatisticService,
    StripeService,
    SubscriptionsService,
    TagsService,
    TeamsService,
    TranslationsService,
    UnitsService,
    UserMealsService,
    UserWorkoutsService,
    UsersService,
    VersionsService,
    HabitsService,
    WorkoutsService,
    QuizzesService,
    QuizQuestionsService,
    UserQuizzesService,
    UserRemindersService,
    OrganizationAdminsService,
    WorkoutCategoriesService,
    ExerciseEquipmentService,
    ExercisePrimaryMusclesService,
    ExerciseCategoriesService,
    ExercisePatternsService,
    ExercisePlanesService,
    HabitCategoriesService,
    MetricCategoriesService,
    QuizEventsService,
    OwnershipLevelsService,
    ReflectionEventsService,
    UserReflectionEventsService,
    HabitEventsService,
    UserHabitEventsService,
    UserHabitEventLogsService,
  ],
})
export class ApiModule {
  public static forRoot(
    configurationFactory: () => Configuration,
  ): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }],
    };
  }

  constructor(
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient,
  ) {
    if (parentModule) {
      throw new Error(
        'ApiModule is already loaded. Import in your base AppModule only.',
      );
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575',
      );
    }
  }
}
