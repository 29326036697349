/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AssignProgramCommand } from '../model/assignProgramCommand';
import { AssignWorkoutCommand } from '../model/assignWorkoutCommand';
import { AssignWorkoutCommandResult } from '../model/assignWorkoutCommandResult';
import { DeleteUserWorkoutActivityCommand } from '../model/deleteUserWorkoutActivityCommand';
import { GetSingleUserScheduleDayQueryResult } from '../model/getSingleUserScheduleDayQueryResult';
import { GetUserScheduleQueryResult } from '../model/getUserScheduleQueryResult';
import { GetUserWorkoutDetailsQueryResult } from '../model/getUserWorkoutDetailsQueryResult';
import { GetUserWorkoutQueryResult } from '../model/getUserWorkoutQueryResult';
import { GetUserWorkoutsCalendarQueryResult } from '../model/getUserWorkoutsCalendarQueryResult';
import { GetWorkoutSubmissionQueryResult } from '../model/getWorkoutSubmissionQueryResult';
import { InlineResponse400 } from '../model/inlineResponse400';
import { SubmitWorkoutCommand } from '../model/submitWorkoutCommand';
import { UpdateUserScheduleCommand } from '../model/updateUserScheduleCommand';
import { UpdateUserWorkoutActivityAttributesCommand } from '../model/updateUserWorkoutActivityAttributesCommand';
import { UpdateUserWorkoutActivityOrdinalsCommand } from '../model/updateUserWorkoutActivityOrdinalsCommand';
import { UpdateUserWorkoutCommand } from '../model/updateUserWorkoutCommand';
import { UpdateWorkoutStatusesCommand } from '../model/updateWorkoutStatusesCommand';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UserWorkoutsService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * [PORTAL]-[MOBILE] Assigns workout to a user
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userWorkoutsAssignPost(body?: AssignWorkoutCommand, observe?: 'body', reportProgress?: boolean): Observable<AssignWorkoutCommandResult>;
    public userWorkoutsAssignPost(body?: AssignWorkoutCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AssignWorkoutCommandResult>>;
    public userWorkoutsAssignPost(body?: AssignWorkoutCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AssignWorkoutCommandResult>>;
    public userWorkoutsAssignPost(body?: AssignWorkoutCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<AssignWorkoutCommandResult>('post',`${this.basePath}/UserWorkouts/Assign`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [PORTAL] Assigns program to a user
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userWorkoutsAssignProgramPost(body?: AssignProgramCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public userWorkoutsAssignProgramPost(body?: AssignProgramCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public userWorkoutsAssignProgramPost(body?: AssignProgramCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public userWorkoutsAssignProgramPost(body?: AssignProgramCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/UserWorkouts/AssignProgram`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [PORTAL] Updates attributes of workout activity
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userWorkoutsAttributesPut(body?: UpdateUserWorkoutActivityAttributesCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public userWorkoutsAttributesPut(body?: UpdateUserWorkoutActivityAttributesCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public userWorkoutsAttributesPut(body?: UpdateUserWorkoutActivityAttributesCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public userWorkoutsAttributesPut(body?: UpdateUserWorkoutActivityAttributesCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/UserWorkouts/Attributes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [PORTAL]-[MOBILE] Gets user workout program days/day
     * 
     * @param clientUid 
     * @param startDateLocal 
     * @param endDateLocal 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userWorkoutsGet(clientUid?: string, startDateLocal?: string, endDateLocal?: string, observe?: 'body', reportProgress?: boolean): Observable<GetUserScheduleQueryResult>;
    public userWorkoutsGet(clientUid?: string, startDateLocal?: string, endDateLocal?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetUserScheduleQueryResult>>;
    public userWorkoutsGet(clientUid?: string, startDateLocal?: string, endDateLocal?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetUserScheduleQueryResult>>;
    public userWorkoutsGet(clientUid?: string, startDateLocal?: string, endDateLocal?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (clientUid !== undefined && clientUid !== null) {
            queryParameters = queryParameters.set('ClientUid', <any>clientUid);
        }
        if (startDateLocal !== undefined && startDateLocal !== null) {
            queryParameters = queryParameters.set('StartDateLocal', <any>startDateLocal);
        }
        if (endDateLocal !== undefined && endDateLocal !== null) {
            queryParameters = queryParameters.set('EndDateLocal', <any>endDateLocal);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetUserScheduleQueryResult>('get',`${this.basePath}/UserWorkouts`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [PORTAL] Gets single user workout details
     * 
     * @param userWorkoutUid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userWorkoutsGetSingleDayGet(userWorkoutUid?: string, observe?: 'body', reportProgress?: boolean): Observable<GetSingleUserScheduleDayQueryResult>;
    public userWorkoutsGetSingleDayGet(userWorkoutUid?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetSingleUserScheduleDayQueryResult>>;
    public userWorkoutsGetSingleDayGet(userWorkoutUid?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetSingleUserScheduleDayQueryResult>>;
    public userWorkoutsGetSingleDayGet(userWorkoutUid?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userWorkoutUid !== undefined && userWorkoutUid !== null) {
            queryParameters = queryParameters.set('UserWorkoutUid', <any>userWorkoutUid);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetSingleUserScheduleDayQueryResult>('get',`${this.basePath}/UserWorkouts/GetSingleDay`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [MOBILE] Client gets personal workout by day
     * 
     * @param userWorkoutUid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userWorkoutsGetSingleGet(userWorkoutUid?: string, observe?: 'body', reportProgress?: boolean): Observable<GetUserWorkoutQueryResult>;
    public userWorkoutsGetSingleGet(userWorkoutUid?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetUserWorkoutQueryResult>>;
    public userWorkoutsGetSingleGet(userWorkoutUid?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetUserWorkoutQueryResult>>;
    public userWorkoutsGetSingleGet(userWorkoutUid?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userWorkoutUid !== undefined && userWorkoutUid !== null) {
            queryParameters = queryParameters.set('UserWorkoutUid', <any>userWorkoutUid);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetUserWorkoutQueryResult>('get',`${this.basePath}/UserWorkouts/GetSingle`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [PORTAL] Reorders and moves workout activities
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userWorkoutsOrdinalReorderPut(body?: UpdateUserWorkoutActivityOrdinalsCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public userWorkoutsOrdinalReorderPut(body?: UpdateUserWorkoutActivityOrdinalsCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public userWorkoutsOrdinalReorderPut(body?: UpdateUserWorkoutActivityOrdinalsCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public userWorkoutsOrdinalReorderPut(body?: UpdateUserWorkoutActivityOrdinalsCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/UserWorkouts/OrdinalReorder`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [MOBILE] Changes workout status and creates comments on selected exercises 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userWorkoutsSubmitPost(body?: SubmitWorkoutCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public userWorkoutsSubmitPost(body?: SubmitWorkoutCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public userWorkoutsSubmitPost(body?: SubmitWorkoutCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public userWorkoutsSubmitPost(body?: SubmitWorkoutCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/UserWorkouts/Submit`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [PORTAL]-[MOBILE] Deletes assigned user workout
     * 
     * @param uid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userWorkoutsUidDelete(uid: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public userWorkoutsUidDelete(uid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public userWorkoutsUidDelete(uid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public userWorkoutsUidDelete(uid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling userWorkoutsUidDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/UserWorkouts/${encodeURIComponent(String(uid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [PORTAL] Gets user workout day details
     * 
     * @param uid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userWorkoutsUidGet(uid: string, observe?: 'body', reportProgress?: boolean): Observable<GetUserWorkoutDetailsQueryResult>;
    public userWorkoutsUidGet(uid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetUserWorkoutDetailsQueryResult>>;
    public userWorkoutsUidGet(uid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetUserWorkoutDetailsQueryResult>>;
    public userWorkoutsUidGet(uid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling userWorkoutsUidGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetUserWorkoutDetailsQueryResult>('get',`${this.basePath}/UserWorkouts/${encodeURIComponent(String(uid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userWorkoutsUpdateStatusesPut(body?: UpdateWorkoutStatusesCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public userWorkoutsUpdateStatusesPut(body?: UpdateWorkoutStatusesCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public userWorkoutsUpdateStatusesPut(body?: UpdateWorkoutStatusesCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public userWorkoutsUpdateStatusesPut(body?: UpdateWorkoutStatusesCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/UserWorkouts/UpdateStatuses`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [PORTAL] Reorders and moves workouts in client scheduler
     * 
     * @param userUid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userWorkoutsUserUidPut(userUid: string, body?: UpdateUserScheduleCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public userWorkoutsUserUidPut(userUid: string, body?: UpdateUserScheduleCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public userWorkoutsUserUidPut(userUid: string, body?: UpdateUserScheduleCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public userWorkoutsUserUidPut(userUid: string, body?: UpdateUserScheduleCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userUid === null || userUid === undefined) {
            throw new Error('Required parameter userUid was null or undefined when calling userWorkoutsUserUidPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/UserWorkouts/${encodeURIComponent(String(userUid))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [PORTAL] Deletes user workout activity
     * 
     * @param uid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userWorkoutsUserWorkoutActivityUidDelete(uid: string, body?: DeleteUserWorkoutActivityCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public userWorkoutsUserWorkoutActivityUidDelete(uid: string, body?: DeleteUserWorkoutActivityCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public userWorkoutsUserWorkoutActivityUidDelete(uid: string, body?: DeleteUserWorkoutActivityCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public userWorkoutsUserWorkoutActivityUidDelete(uid: string, body?: DeleteUserWorkoutActivityCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling userWorkoutsUserWorkoutActivityUidDelete.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('delete',`${this.basePath}/UserWorkouts/UserWorkoutActivity/${encodeURIComponent(String(uid))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get users workout submission for a day
     * 
     * @param assignedDateLocal 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userWorkoutsUserWorkoutSubmissionGet(assignedDateLocal?: string, observe?: 'body', reportProgress?: boolean): Observable<GetUserWorkoutsCalendarQueryResult>;
    public userWorkoutsUserWorkoutSubmissionGet(assignedDateLocal?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetUserWorkoutsCalendarQueryResult>>;
    public userWorkoutsUserWorkoutSubmissionGet(assignedDateLocal?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetUserWorkoutsCalendarQueryResult>>;
    public userWorkoutsUserWorkoutSubmissionGet(assignedDateLocal?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (assignedDateLocal !== undefined && assignedDateLocal !== null) {
            queryParameters = queryParameters.set('AssignedDateLocal', <any>assignedDateLocal);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetUserWorkoutsCalendarQueryResult>('get',`${this.basePath}/UserWorkouts/UserWorkoutSubmission`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [MOBILE] Get users workout submission days for period
     * 
     * @param startDateLocal 
     * @param endDateLocal 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userWorkoutsUserWorkoutSubmissionsGet(startDateLocal?: string, endDateLocal?: string, observe?: 'body', reportProgress?: boolean): Observable<GetWorkoutSubmissionQueryResult>;
    public userWorkoutsUserWorkoutSubmissionsGet(startDateLocal?: string, endDateLocal?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetWorkoutSubmissionQueryResult>>;
    public userWorkoutsUserWorkoutSubmissionsGet(startDateLocal?: string, endDateLocal?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetWorkoutSubmissionQueryResult>>;
    public userWorkoutsUserWorkoutSubmissionsGet(startDateLocal?: string, endDateLocal?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startDateLocal !== undefined && startDateLocal !== null) {
            queryParameters = queryParameters.set('StartDateLocal', <any>startDateLocal);
        }
        if (endDateLocal !== undefined && endDateLocal !== null) {
            queryParameters = queryParameters.set('EndDateLocal', <any>endDateLocal);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetWorkoutSubmissionQueryResult>('get',`${this.basePath}/UserWorkouts/UserWorkoutSubmissions`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [PORTAL] Updates user workout
     * 
     * @param uid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userWorkoutsUserWorkoutUidPut(uid: string, body?: UpdateUserWorkoutCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public userWorkoutsUserWorkoutUidPut(uid: string, body?: UpdateUserWorkoutCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public userWorkoutsUserWorkoutUidPut(uid: string, body?: UpdateUserWorkoutCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public userWorkoutsUserWorkoutUidPut(uid: string, body?: UpdateUserWorkoutCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling userWorkoutsUserWorkoutUidPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/UserWorkouts/UserWorkout/${encodeURIComponent(String(uid))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
