import { DateTime } from 'luxon';

export const getDayDifference = (date1: DateTime, date2: DateTime) => {
  const dateOnly1 = DateTime.fromObject({
    year: date1.year,
    month: date1.month,
    day: date1.day,
  });
  const dateOnly2 = DateTime.fromObject({
    year: date2.year,
    month: date2.month,
    day: date2.day,
  });
  const duration = dateOnly1.diff(dateOnly2, 'days').days;
  const daysDifference = Math.floor(duration);

  return Math.abs(daysDifference);
};
